<template>
  <v-container id="hoteles" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-bed"
      inline
      class="px-5 py-3"
      :title="$t('hotels')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Hotel</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Empieza Dialogo Agregar/Editar Hotel-->
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card flat>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Nombre del Hotel -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Hotel*"
                    maxlength="50"
                    required
                    v-model="editedItem.hotel"
                    :error-messages="hotelErrors"
                    @blur="$v.editedItem.hotel.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.hotel.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- direccion.calle -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Calle*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.direccion.calle.$model"
                    :error-messages="calleErrors"
                    @blur="$v.editedItem.direccion.calle.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.direccion.calle.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- direccion.numero -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Número*"
                    maxlength="50"
                    required
                    :error-messages="numeroErrors"
                    v-model="$v.editedItem.direccion.numero.$model"
                    @blur="$v.editedItem.direccion.numero.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.direccion.numero.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- ubicacion.contacto.telefono -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Teléfono"
                    maxlength="10"
                    v-model="editedItem.contacto.telefono"
                    @change="onTextChange"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- ubicacion.lat -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Latitud"
                    maxlength="10"
                    v-model="editedItem.ubicacion.lat"
                    :error-messages="latErrors"
                    @blur="$v.editedItem.ubicacion.lat.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.ubicacion.lat.$reset()"
                    @keypress="isNumber($event, true)"
                  ></v-text-field>
                </v-col>

                <!-- ubicacion.lng -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Longitud"
                    maxlength="10"
                    v-model="editedItem.ubicacion.lng"
                    :error-messages="lngErrors"
                    @blur="$v.editedItem.ubicacion.lng.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.ubicacion.lng.$reset()"
                    @keypress="isNumber($event, true)"
                  ></v-text-field>
                </v-col>

                <!-- ubicacion.gmaps -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Google Maps"
                    maxlength="50"
                    v-model="editedItem.ubicacion.gmaps"
                    :error-messages="gmapsErrors"
                    @blur="$v.editedItem.ubicacion.gmaps.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.ubicacion.gmaps.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Zona -->
                <v-col cols="12">
                  <!-- Lista de seleccion en el formulario para LAS ZONAS -->
                  <v-select
                    dense
                    hint="Selecciona Zona"
                    item-text="zona"
                    item-value="_id"
                    label="Zona*"
                    persistent-hint
                    return-object
                    required
                    v-model="editedItem.direccion.zona"
                    :items="zonas"
                    :menu-props="{ top: true, offsetY: true }"
                  ></v-select>
                  {{ editedItem.zonas }}
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de Hoteles -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="hoteles"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <!-- Creo el boton de Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <template v-slot:no-data>¡No hay datos para mostrar!</template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="hoteles" :dataFields="fields" fileName="Hoteles" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

const latlngNumbers = (value) => {
  if (!value) {
    return true
  }
  const match = String(value).match(/^[-|+|.|0-9]/) || []
  return match.length >= 1
}

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    headers: [
      { text: 'Hotel', align: 'start', sortable: false, value: 'hotel' },
      // <!-- direccion -->
      { text: 'Calle', align: 'start', sortable: false, value: 'direccion.calle' },
      { text: 'Número', align: 'start', sortable: false, value: 'direccion.numero' },
      { text: 'Zona', align: 'start', sortable: false, value: 'direccion.zona.zona' },
      // <!-- ubicacion -->
      { text: 'Latitud', align: 'start', sortable: false, value: 'ubicacion.lat' },
      { text: 'Longitud', align: 'start', sortable: false, value: 'ubicacion.lng' },
      { text: 'Google Maps', align: 'start', sortable: false, value: 'ubicacion.gmaps' },
      // <!-- contacto -->
      { text: 'Teléfono', align: 'start', sortable: false, value: 'contacto.telefono' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    hoteles: [],
    value: [],
    zonas: [],
    defaultItem: {
      _id: '',
      hotel: '',
      direccion: { calle: '', numero: '', zona: '' },
      zona: '',
      ubicacion: { lat: '', lng: '', gmaps: '' },
      contacto: { telefono: '' },
      activo: true,
    },
    editedItem: {
      _id: '',
      hotel: '',
      direccion: { calle: '', numero: '', zona: '' },
      zona: '',
      ubicacion: { lat: '', lng: '', gmaps: '' },
      contacto: { telefono: '' },
      activo: true,
    },
    fields: {
      Hotel: 'hotel',
      Calle: 'direccion.calle',
      Numero: 'direccion.numero',
      Zona: 'direccion.zona.zona',
      Latitud: 'ubicacion.lat',
      Longitud: 'ubicacion.longitud',
      Contacto: 'contacto.telefono',
    },
  }),

  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      hotel: { required, minLength: minLength(3), maxLength: maxLength(50) },
      direccion: {
        calle: { required, minLength: minLength(3), maxLength: maxLength(50) },
        numero: { required, minLength: minLength(3), maxLength: maxLength(50) },
        zona: { required, minLength: minLength(1), maxLength: maxLength(50) },
      },
      ubicacion: {
        lat: { minLength: minLength(6), maxLength: maxLength(10), latlngNumbers },
        lng: { minLength: minLength(6), maxLength: maxLength(10), latlngNumbers },
        gmaps: { minLength: minLength(3), maxLength: maxLength(50) },
      },
      /*   contacto: {
        telefono: { minLength: minLength(3), maxLength: maxLength(50) },
      }, */
      // telefono: { required, minLength: minLength(5), maxLength: maxLength(20) },
      // googlemaps: { required, minLength: minLength(5), maxLength: maxLength(50) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Hotel' : 'Editar Hotel'
    },

    hotelErrors() {
      const errors = []
      if (!this.$v.editedItem.hotel.$dirty) return errors
      !this.$v.editedItem.hotel.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.hotel.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.hotel.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    // direccion.calle
    calleErrors() {
      const errors = []
      if (!this.$v.editedItem.direccion.calle.$dirty) return errors
      !this.$v.editedItem.direccion.calle.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.direccion.calle.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.direccion.calle.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
    // direccion.numero
    numeroErrors() {
      const errors = []
      if (!this.$v.editedItem.direccion.numero.$dirty) return errors
      !this.$v.editedItem.direccion.numero.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.direccion.numero.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.direccion.numero.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
    // direccion.zona
    /*  zonaErrors() {
      const errors = []
      if (!this.$v.editedItem.direccion.zona.$dirty) return errors
      !this.$v.editedItem.direccion.zona.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.direccion.zona.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.direccion.zona.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    }, */
    // ubicacion.lat
    latErrors() {
      const errors = []
      if (!this.$v.editedItem.ubicacion.lat.$dirty) return errors
      //!this.$v.editedItem.ubicacion.lat.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.ubicacion.lat.latlngNumbers && errors.push('Este campo debe ser númerico')
      !this.$v.editedItem.ubicacion.lat.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.ubicacion.lat.maxLength &&
        errors.push('Este campo debe de tener un máximo de 9 caracteres.')
      return errors
    },
    // ubicacion.lng
    lngErrors() {
      const errors = []
      if (!this.$v.editedItem.ubicacion.lng.$dirty) return errors
      //!this.$v.editedItem.ubicacion.lng.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.ubicacion.lng.latlngNumbers && errors.push('Este campo debe ser númerico')
      !this.$v.editedItem.ubicacion.lng.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.ubicacion.lng.maxLength &&
        errors.push('Este campo debe de tener un máximo de 9 caracteres.')
      return errors
    },
    // ubicacion.gmaps
    gmapsErrors() {
      const errors = []
      if (!this.$v.editedItem.ubicacion.gmaps.$dirty) return errors
      //!this.$v.editedItem.ubicacion.gmaps.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.ubicacion.gmaps.minLength &&
        errors.push('Este campo debe de tener un mínimo de 5 caracteres.')
      !this.$v.editedItem.ubicacion.gmaps.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
    /*     telefonoErrors() {
      const errors = []
      if (!this.$v.editedItem.contacto.telefono.$dirty) return errors
      !this.$v.editedItem.contacto.telefono.required && errors.push('Este campo es requerido1')
      !this.$v.editedItem.contacto.telefono.minLength &&
        errors.push('Este campo debe de tener un mínimo de 5 caracteres.')
      !this.$v.editedItem.contacto.telefono.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    }, */
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerZonas()
    this.obtenerHoteles()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('hotel/deleteHotel', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si se cambio el valor de algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Elimina un hotel
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.hoteles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.hoteles.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo signa al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.hoteles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Evalua el caracter para saber si es un numero
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Obtiene las listado de hoteles
     */
    obtenerHoteles() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('hotel/fetchHotels', payload)
        .then((response) => {
          this.hoteles = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err.response)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error',
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene las zonas de hotel
     */
    obtenerZonas() {
      let payload = { params: '?campo1=zona&orden1=1' }
      this.$store
        .dispatch('zone/fetchZones', payload)
        .then((response) => {
          this.zonas = response.data
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del dialogo
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          hotel: this.editedItem.hotel,
          //direccion: this.editedItem.direccion,
          direccion: {
            calle: this.editedItem.direccion.calle,
            numero: this.editedItem.direccion.numero,
            zona: this.editedItem.direccion.zona,
          },
          // zona: this.editedItem.zona,
          ubicacion: this.editedItem.ubicacion,
          contacto: this.editedItem.contacto,
          // telefono: this.editedItem.telefono,
          // googlemaps: this.editedItem.googlemaps,
        }
        // console.log(body)

        let payload = { body: body }
        if (this.editedIndex > -1) {
          //payload.id = this.editedItem._id
          payload.id = this.editedItem._id
          this.$store
            .dispatch('hotel/editHotel', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.hoteles[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('hotel/addHotel', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.hoteles.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
